import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {checkRedirect, initSdk, invoke, asyncCall, call, SignRes} from 'wecom-sidebar-jssdk';


// 企业微信参考https://wecom-sidebar.github.io/wecom-sidebar-docs/jssdk/hello_world.html#%E4%B8%BA%E4%BB%80%E4%B9%88
// 侧边栏配置
const config = {
  // 在 https://work.weixin.qq.com/wework_admin/frame#profile 这里可以找到
  corpId: 'ww0804b2766f1b832f',
  // 在 https://work.weixin.qq.com/wework_admin/frame#apps 里的自建应用里可以找到
  agentId: '1000002'
}

// 获取签名接口（需要后端生成）
// const fetchSignatures = async (): Promise<SignRes> => {
//   const response = await axios.request<SignRes>({
//     method: 'GET',
//     url: '/api-work/qywx-utils/signatures',
//     params: {
//       url: '123123'
//     }
//   })
//   console.log('signatures resp:', response)
//   return response.data;
// }

const fetchSignatures = async (): Promise<SignRes> => {
  const response = await axios.request({
    method: 'GET',
    url: '/api-work/qywx-utils/signatures',
    params: {url: window.location.href}
  });
  console.log('signatures resp:', response)
  return response.data;
}

// code 换取用户身份（需要后端调用企微服务端 API）
const fetchUserId = async (code: string): Promise<string> => {
    console.log('code:', code)
  const response = await axios.request({
    method: 'GET',
    url: '/api-work/qywx-proxy/user/getuserinfo',
    params: {code}
  });
   console.log('getuserinfo', response)
  return response.data.userId;
}


const render = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
}

// fetchSignatures().then((res) => {
//     render();
// })
checkRedirect(config, fetchUserId)
  .then(() => initSdk(config, fetchSignatures))
  .then(res => {
      console.log(res,'reayd')
      render();
  })
  .catch((e) => {
      alert('sdk error');
      alert(JSON.stringify(e));
      console.error('JS-SDK 初始化失败')
  })
