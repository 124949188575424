import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {checkRedirect, initSdk, invoke, asyncCall, call, SignRes} from 'wecom-sidebar-jssdk';


function App() {
const  [user_id, setUserId] = React.useState('init');
const  [error, setError] = React.useState('');

React.useEffect(() => {
    // (async function init() {
    //     const res1 = await invoke('getCurExternalContact');
    //     setUserId(res1.userId);
    // })()
}, [])

 const handleClick = async () => {
       const res1 = await invoke('getCurExternalContact');
        setUserId(res1.userId);
 }
 
  const handleSend = async () => {
      try{
         const res1 = await asyncCall('openEnterpriseChat', {
            // userIds: ['GaoShanYangZhi'].join(';'),
            groupName:"",
            externalUserIds: 'wmE3gOTAAAZ_XUkFjTKH5dMhhC_XwCxg',
       });  
      }catch(e){
          setError(e.toString())
      }
      
       
 }
 const selectExternalContact = async () => {
     await invoke('selectExternalContact', {"filterType": 0});
 }
 
  return (
      <>
    <div className="App">
        {user_id}
    </div>
     <div className="App2">
        {error}
    </div>
    <button onClick={handleClick}>获取外部联系人userId</button>
    <button onClick={handleSend}>打开会话2</button>
    <button onClick={selectExternalContact}>selectExternalContact</button>
    </>
  );
}

export default App;
